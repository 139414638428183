import Image from 'next/image';
import type { CSSProperties } from 'react';
import { Shimmer, toBase64 } from '../../utility/imageShimmer/ImageShimmer';
import styles from './IntroCard.module.scss';

export type ImageType = {
  url: string;
  title: string;
  height: number;
  width: number;
};

export type IntroCardProps = {
  title: string;
  callToAction: string;
  contentColor: string;
  image: ImageType;
};

/**
 * Intro Card Component.
 * @param props -  Component props.
 */
export const IntroCard = ({
  title,
  callToAction,
  contentColor,
  image,
  isLoading,
}: IntroCardProps & { isLoading: boolean }) => {
  if (isLoading) {
    return <div className={[styles.introCard, 'is-loading'].join(' ')}></div>;
  }

  return (
    <div
      className={styles.introCard}
      style={
        {
          '--card-color': contentColor,
        } as CSSProperties
      }
    >
      <div className={styles.introCard__imageWrapper}>
        <Image
          src={image?.url}
          alt={image?.title}
          title={image?.title}
          fill
          sizes="340px"
          placeholder="blur"
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            Shimmer(image?.width, image?.height)
          )}`}
        />
      </div>

      <div className={styles.introCard__content}>
        <div>
          <h5
            className={[
              styles.introCard__title,
              'text-heading-h5-mobile text-heading-h5-desktop',
            ].join(' ')}
          >
            {title}
          </h5>
          <p
            className={[
              styles.introCard__cta,
              'text-utility-utility-small-mobile',
            ].join(' ')}
          >
            {callToAction}
          </p>
        </div>
      </div>
    </div>
  );
};
