import clsx from 'clsx';
import { useState } from 'react';
import { IntroCard } from '../../../components/cards/intro/IntroCard';
import type { IntroCardProps } from '../../../components/cards/intro/IntroCard';
import { SystemCard } from '../../../components/cards/system/SystemCard';
import type { SystemCardProps } from '../../../components/cards/system/SystemCard';
import { Button } from '../../../components/ui/button/Button';
import { ScrollSnapSlider } from '../../../components/utility/scrollSnapSlider/ScrollSnapSlider';
import styles from './FindYourSystem.module.scss';

export type SystemListingType = {
  title: string;
  introCard: IntroCardProps;
  systemCards: Array<SystemCardProps>;
};

export type FindYourSystemProps = {
  title: string;
  systemListings: Array<SystemListingType>;
  isLoading: boolean;
};

/**
 * Find Your System Component.
 * @param props -  Component props.
 */
export const FindYourSystem = ({
  title,
  systemListings,
  isLoading,
}: FindYourSystemProps) => {
  const [activeListingIndex, setActiveListingIndex] = useState(0);

  if (isLoading) {
    return (
      <section className={styles.findYourSystem__wrapper}>
        <div className={styles.findYourSystem}>
          <div className={clsx(styles.findYourSystem__header, 'container')}>
            <div
              className={clsx(styles.findYourSystem__title, 'is-loading')}
            ></div>

            <div className={styles.findYourSystem__controls}>
              {[...Array(4).keys()].map((index) => (
                <div
                  key={index}
                  className={clsx(styles.findYourSystem__control, 'is-loading')}
                ></div>
              ))}
            </div>
          </div>

          <div className={clsx(styles.findYourSystem__listing, 'is-loading')}>
            {[...Array(4).keys()].map((index) => (
              <div
                key={index}
                className={clsx(styles.findYourSystem__card, 'is-loading')}
              ></div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (systemListings.length) {
    return (
      <div className={styles.findYourSystem__wrapper}>
        <section className={styles.findYourSystem}>
          <div className={clsx(styles.findYourSystem__header, 'container')}>
            <h2 className="text-heading-h2-mobile text-heading-h2-desktop">
              {title}
            </h2>

            <div className={styles.findYourSystem__controls}>
              {systemListings.map((systemListing, index) => (
                <Button
                  key={index}
                  type="button"
                  modifiers="text"
                  className={clsx(styles.findYourSystem__control, {
                    ['isActive']: index === activeListingIndex,
                  })}
                  onClick={() => {
                    setActiveListingIndex(index);
                  }}
                >
                  <h3
                    className={clsx(
                      'text-utility-utility-mobile',
                      'text-utility-utility-desktop'
                    )}
                  >
                    {systemListing.title}
                  </h3>
                </Button>
              ))}
            </div>
          </div>

          {systemListings.map((systemListing, index) => (
            <SystemListing
              key={index}
              {...systemListing}
              isLoading={isLoading}
              isActive={index === activeListingIndex}
            />
          ))}
        </section>
      </div>
    );
  }

  return <></>;
};

/**
 * Color System Listing Component.
 * @param props -  Component props.
 */
const SystemListing = (
  props: SystemListingType & { isLoading: boolean; isActive: boolean }
) => {
  const { isActive, isLoading, introCard, systemCards } = props;

  return (
    <ScrollSnapSlider
      height="100%"
      spacing="5px"
      className={clsx(
        styles.findYourSystem__carousel,
        !isActive && styles.findYourSystem__hideCarousel
      )}
      clickToSwitch
    >
      <div
        className={clsx(
          styles.findYourSystem__card,
          styles.findYourSystem__introCard
        )}
      >
        <IntroCard {...introCard} isLoading={isLoading} />
      </div>
      {systemCards.map((systemCard, index) => {
        return (
          <div key={index} className={styles.findYourSystem__card}>
            <SystemCard {...systemCard} isLoading={isLoading} />
          </div>
        );
      })}
    </ScrollSnapSlider>
  );
};
