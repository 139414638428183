import Image from 'next/image';
import type { CSSProperties } from 'react';
import { Button } from '../../../components/ui/button/Button';
import {
  Shimmer,
  toBase64,
} from '../../../components/utility/imageShimmer/ImageShimmer';
import type { ImageType } from '../../../types.d/storeTypes';
import styles from './SystemCard.module.scss';

export type SystemCardProps = {
  title: string;
  description: string;
  contentColor: string;
  productImage: ImageType;
  icons: Array<ImageType>;
  cta: {
    title: string;
    url: string;
    newTab: boolean;
  };
  systemColor: string;
};

/**
 * System Card Component.
 * @param props -  Component props.
 */
export const SystemCard = ({
  title,
  description,
  contentColor,
  productImage,
  icons,
  cta,
  systemColor,
  isLoading,
}: SystemCardProps & { isLoading: boolean }) => {
  if (isLoading) {
    return <div className={[styles.systemCard, 'is-loading'].join(' ')}></div>;
  }

  return (
    <div
      className={styles.systemCard}
      style={
        {
          '--card-background-color': systemColor,
          '--card-color': contentColor,
        } as CSSProperties
      }
    >
      <div className={styles.systemCard__details}>
        {productImage.url && (
          <div className={styles.systemCard__imageWrapper}>
            <Image
              src={productImage.url}
              alt={productImage.title}
              title={productImage.title}
              fill
              sizes="104px"
              placeholder="blur"
              blurDataURL={`data:image/svg+xml;base64,${toBase64(
                Shimmer(productImage.width, productImage.height)
              )}`}
            />
          </div>
        )}
        <div>
          <h4
            className={[
              styles.systemCard__title,
              'text-heading-h5-mobile text-heading-h5-desktop',
            ].join(' ')}
          >
            {title}
          </h4>
          <p
            className={[
              styles.systemCard__description,
              'text-body-1-mobile text-body-1-desktop',
            ].join(' ')}
          >
            {description}
          </p>
        </div>
      </div>

      <div className={styles.systemCard__icons}>
        {icons.map((icon, index) => {
          return (
            <div key={index} className={styles.systemCard__icon}>
              {icon.url && (
                <Image
                  className={styles.systemCard__image}
                  src={icon.url}
                  alt={icon.title}
                  title={icon.title}
                  height={icon.height}
                  width={icon.width}
                  placeholder="blur"
                  blurDataURL={`data:image/svg+xml;base64,${toBase64(
                    Shimmer(icon.width, icon.height)
                  )}`}
                />
              )}

              <p
                className={[
                  styles.systemCard__iconTitle,
                  'text-blog-body-caption-mobile',
                ].join(' ')}
              >
                {icon.title}
              </p>
            </div>
          );
        })}
      </div>

      {cta.url && cta.title && (
        <Button
          type="button"
          modifiers="white"
          className={styles.systemCard__cta}
          href={cta.url}
          target={cta.newTab ? '_blank' : undefined}
        >
          <span className="text-utility-utility-small">{cta.title}</span>
        </Button>
      )}
    </div>
  );
};
